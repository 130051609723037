import { API_ENDPOINT } from '@utils'
// import useJwt from '@src/@core/auth/jwt/useJwt'
import useJwt from '@src/auth/jwt/useJwt'

const URL = API_ENDPOINT()
const axios = useJwt.getAxios()

axios.defaults.baseURL = URL

export default axios

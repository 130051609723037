/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getData = createAsyncThunk(
  'appApprovalStudent/getData',
  async (params) => {
    const response = await axios.get(`/students-approval-pending`, {
      params
    })
    return {
      params,
      data: response.data.data,
      pagination: response.data.pagination
    }
  }
)

export const getStudent = createAsyncThunk(
  'appApprovalStudent/getStudent',
  async (id) => {
    const response = await axios.get(`/students/show/${id}`)
    return response.data.data
  }
)

export const approveStudent = createAsyncThunk(
  'appApprovalStudent/approveStudent',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`students/student-approve/${id}`, data)
      await dispatch(getData(getState().pAStudent.params))
      navigator ? navigator('/p/student/approval-list') : ''
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const appApprovalStudentSlice = createSlice({
  name: 'appApprovalStudent',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } =
  appApprovalStudentSlice.actions

export default appApprovalStudentSlice.reducer

// service-worker.js
;(() => {
  'use strict'

  // Optimized logger with performance markers
  const logger = {
    colors: { log: '#000', warn: '#C25700', error: '#F00' },
    style: 'color:white;padding:0 2px;background:',
    log(...args) {
      this._print('log', ...args)
    },
    warn(...args) {
      this._print('warn', ...args)
    },
    error(...args) {
      this._print('error', ...args)
    },
    _print(type, ...args) {
      const ts = performance.now().toFixed(2)
      let msg = `%c${ts}ms [SW] ${type.toUpperCase()}`
      if (typeof args[0] === 'string') msg += `: ${args.shift()}`
      console[type](msg, `${this.style}${this.colors[type]}`, ...args)
    }
  }

  // Cache configuration
  const CACHE_NAME = 'v1.5'
  const PRECACHE_URLS = ['/', '/main.css', '/app.js', '/fallback.html']

  // Installation and activation
  self.addEventListener('install', (e) => {
    logger.log('Installing...')
    e.waitUntil(
      caches
        .open(CACHE_NAME)
        .then((cache) => cache.addAll(PRECACHE_URLS))
        .then(() => self.skipWaiting())
    )
  })

  self.addEventListener('fetch', (e) => {
    const url = new URL(e.request.url)

    // Exclude API requests from caching
    if (url.pathname.startsWith('/api')) {
      return // Let the browser handle API requests directly
    }

    // Cache other resources (e.g., static files)
    e.respondWith(
      caches.match(e.request).then((cachedResponse) => {
        return (
          cachedResponse ||
          fetch(e.request).then((response) => {
            return caches.open(CACHE_NAME).then((cache) => {
              cache.put(e.request, response.clone())
              return response
            })
          })
        )
      })
    )
  })

  self.addEventListener('activate', (e) => {
    logger.log('Activating...')
    e.waitUntil(
      caches
        .keys()
        .then((keys) =>
          Promise.all(keys.map((key) => key !== CACHE_NAME && caches.delete(key)))
        )
        .then(() => self.clients.claim())
    )
  })

  // Network-first with cache fallback strategy
  self.addEventListener('fetch', (e) => {
    const url = new URL(e.request.url)

    // Exclude API requests from caching
    if (url.pathname.startsWith('/api')) {
      return // Let the browser handle API requests directly
    }

    e.respondWith(
      fetch(e.request)
        .then((netRes) => {
          const resClone = netRes.clone()
          caches.open(CACHE_NAME).then((cache) => cache.put(e.request, resClone))
          return netRes
        })
        .catch(() => caches.match(e.request) || caches.match('/fallback.html'))
    )
  })

  // Notification handling
  self.addEventListener('notificationclick', (e) => {
    e.notification.close()
    const url = e.notification.data?.url || self.location.origin

    e.waitUntil(
      self.clients
        .matchAll({ type: 'window' })
        .then((clients) => {
          const chatClient = clients.find((c) => c.url.includes('/chat'))
          return (
            chatClient ||
            (url.startsWith(self.location.origin)
              ? self.clients.openWindow(url)
              : Promise.reject('Invalid URL'))
          )
        })
        .then((client) => {
          client.postMessage({
            action: e.action || 'click',
            data: e.notification.data
          })
          return client.focus()
        })
        .catch(logger.error)
    )
  })
})()

// PWA Installation handling
let deferredPrompt = null
const installManager = {
  init() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      deferredPrompt = e
      this.showInstallPrompt()
      window.addEventListener('appinstalled', () => {
        logger.log('PWA installed successfully')
        deferredPrompt = null
      })
    })
  },
  showInstallPrompt() {
    if (!deferredPrompt) return
    const installBanner = document.getElementById('install-banner')
    const installButton = document.getElementById('install-button')
    if (!installBanner || !installButton) return
    installBanner.hidden = false
    installButton.classList.add('pulse')
    installButton.onclick = () => {
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choice) => {
        installBanner.hidden = true
        installButton.classList.remove('pulse') // Remove the animation
        if (choice.outcome !== 'accepted') {
          setTimeout(() => this.showInstallPrompt(), 30000) // Retry after 30s
        }
      })
    }
  }
}

// Registration logic
export function register(config) {
  if (!('serviceWorker' in navigator)) return

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      /^127(\.\d+){3}$/.test(window.location.hostname)
  )

  window.addEventListener('load', async () => {
    try {
      const swUrl = `/service-worker.js` // Relative path to public folder
      const registration = await navigator.serviceWorker.register(swUrl)

      registration.onupdatefound = () => {
        const worker = registration.installing
        worker.onstatechange = () => {
          if (worker.state === 'installed') {
            navigator.serviceWorker.controller
              ? config?.onUpdate?.(registration)
              : config?.onSuccess?.(registration)
          }
        }
      }

      installManager.init()
    } catch (err) {
      console.error('Service Worker registration failed:', err)
    }
  })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => registration.unregister())
      .catch(console.error)
  }
}

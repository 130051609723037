export const countryNames = [{ value: 'India', label: 'India' }]
export const businessNature = [{ value: 'India', label: 'India' }]

export const enquiryStages = [
  { value: 'Open', label: 'Open' },
  { value: 'Started', label: 'Started' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'No Response', label: 'No Response' },
  { value: 'Re-connect', label: 'Re-connect' },
  { value: 'Won', label: 'Won' },
  { value: 'Loss', label: 'Loss' }
]

export const amcStage = [
  { value: 'Evaluation', label: 'Evaluation' },
  { value: 'Not Interested', label: 'Not Interested' }
]

export const projectStages = [
  { value: 'Open', label: 'Open' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Done', label: 'Done' },
  { value: 'Loss', label: 'Loss' }
]

export const milestoneStages = [
  { value: 'Planning', label: 'Planning' },
  { value: 'Planned', label: 'Planned' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Done', label: 'Done' },
  { value: 'Under Discussion', label: 'Under Discussion' }
]

export const taskStatusOptions = [
  { value: 'Open', label: 'Open' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Invalid', label: 'Invalid' },
  { value: 'Done', label: 'Done' },
  { value: 'On Hold', label: 'On Hold' }
]

export const contactTags = [
  { value: 'Owner', label: 'Owner' },
  { value: 'Accountant', label: 'Accountant' },
  { value: 'Employee', label: 'Employee' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Reseller', label: 'Reseller' }
]

export const studentTags = [
  { value: 'Regular', label: 'Regular' },
  { value: 'New', label: 'New' },
  { value: 'Interested', label: 'Interested' },
  { value: 'On Holidays', label: 'On Holidays' },
  { value: 'Old', label: 'Old' }
]

export const templateTypes = [
  { value: 'GROUP_AMC', label: 'GROUP_AMC' },
  { value: 'AMC', label: 'AMC' }
]

export const gsRole = [
  { value: 'admin', label: 'Admin' },
  { value: 'sayojak', label: 'Sayojak/Coordinator' },
  { value: 'non-teacher', label: 'Non Teacher / View Only' }
]

export const gyanShalaListPublic = [
  {
    label: 'Anuvibha - Gyanshala',
    value: '66fd77bfb0aebafcc572a428'
  },
  {
    label: 'Shyam Nagar - GyanShala',
    value: '66fd77e3b0aebafcc572a43d'
  },
  {
    label: 'VDN - Gyanshala',
    value: '66fd77f7b0aebafcc572a451'
  },
  {
    label: 'Milap Bhawan - GYANSHALA',
    value: '66fd783ab0aebafcc572a466'
  },
  {
    label: 'Mansarovar - GyanShala - Jaipur',
    value: '67ab54e190c5dd071d20d093'
  }
]

export const gyanShalaClasses = [
  {
    value: '67861b607750b0c793bc3c85',
    label: 'SSB 1',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab335b90c5dd071d20cd62',
    label: 'SSB-2',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab337a90c5dd071d20cd86',
    label: 'SSB-3',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab338b90c5dd071d20cd98',
    label: 'SSB-4',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab339f90c5dd071d20cdab',
    label: 'SSB-5',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab348190c5dd071d20cdcb',
    label: 'SSB-1',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab358f90c5dd071d20cde1',
    label: 'SSB-1',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab359890c5dd071d20cde9',
    label: 'SSB-2',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab359f90c5dd071d20cdf1',
    label: 'SSB-3',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab35a590c5dd071d20cdf9',
    label: 'SSB-4',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab35a990c5dd071d20ce02',
    label: 'SSB-5',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab35b990c5dd071d20ce0a',
    label: 'SSB-5',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab35bc90c5dd071d20ce12',
    label: 'SSB-4',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab35c090c5dd071d20ce1a',
    label: 'SSB-3',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab35c390c5dd071d20ce22',
    label: 'SSB-2',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab35c690c5dd071d20ce2a',
    label: 'SSB-1',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab35cf90c5dd071d20ce32',
    label: 'SSB-1',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab35e590c5dd071d20ce3b',
    label: 'SSB-2',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab35e890c5dd071d20ce43',
    label: 'SSB-3',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab35ee90c5dd071d20ce4b',
    label: 'SSB-4',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab35f190c5dd071d20ce53',
    label: 'SSB-5',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab35fb90c5dd071d20ce5b',
    label: 'SSB-5',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab360090c5dd071d20ce63',
    label: 'SSB-4',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab360490c5dd071d20ce6b',
    label: 'SSB-3',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab360890c5dd071d20ce73',
    label: 'SSB-2',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab360c90c5dd071d20ce7b',
    label: 'SSB-1',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab361a90c5dd071d20ce83',
    label: 'SSB-1',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab361d90c5dd071d20ce8b',
    label: 'SSB-2',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab362090c5dd071d20ce93',
    label: 'SSB-3',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab362390c5dd071d20ce9c',
    label: 'SSB-4',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab362690c5dd071d20cea4',
    label: 'SSB-5',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab365190c5dd071d20ceac',
    label: 'SSB-5',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab365790c5dd071d20ceb4',
    label: 'SSB-4',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab365d90c5dd071d20cebd',
    label: 'SSB-3',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab366490c5dd071d20cec5',
    label: 'SSB-2',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab366890c5dd071d20cecd',
    label: 'SSB-1',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab367790c5dd071d20ced5',
    label: 'SSB-1',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab367a90c5dd071d20cedd',
    label: 'SSB-2',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab367e90c5dd071d20cee5',
    label: 'SSB-3',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab368090c5dd071d20ceed',
    label: 'SSB-4',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab368490c5dd071d20cef5',
    label: 'SSB-5',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab373390c5dd071d20cf0a',
    label: 'General',
    location: '677137b568953afcf6238bb3'
  },
  {
    value: '67ab373b90c5dd071d20cf12',
    label: 'General',
    location: '6771376268953afcf6238b9b'
  },
  {
    value: '67ab374190c5dd071d20cf1a',
    label: 'General',
    location: '6771354968953afcf6238b54'
  },
  {
    value: '67ab374690c5dd071d20cf22',
    label: 'General',
    location: '6762ef1d42d6f6ed8fbbe2e9'
  },
  {
    value: '67ab374b90c5dd071d20cf2a',
    label: 'General',
    location: '66fd783ab0aebafcc572a466'
  },
  {
    value: '67ab375690c5dd071d20cf33',
    label: 'General',
    location: '66fd77f7b0aebafcc572a451'
  },
  {
    value: '67ab375c90c5dd071d20cf3b',
    label: 'General',
    location: '66fd77e3b0aebafcc572a43d'
  },
  {
    value: '67ab376290c5dd071d20cf43',
    label: 'General',
    location: '66fd77bfb0aebafcc572a428'
  },
  {
    value: '67ab550490c5dd071d20d0a9',
    label: 'General',
    location: '67ab54e190c5dd071d20d093'
  },
  {
    value: '67ab550f90c5dd071d20d0b1',
    label: 'SSB-1',
    location: '67ab54e190c5dd071d20d093'
  },
  {
    value: '67ab551190c5dd071d20d0ba',
    label: 'SSB-2',
    location: '67ab54e190c5dd071d20d093'
  },
  {
    value: '67ab551490c5dd071d20d0c2',
    label: 'SSB-3',
    location: '67ab54e190c5dd071d20d093'
  },
  {
    value: '67ab551790c5dd071d20d0ca',
    label: 'SSB-4',
    location: '67ab54e190c5dd071d20d093'
  },
  {
    value: '67ab551990c5dd071d20d0d2',
    label: 'SSB-5',
    location: '67ab54e190c5dd071d20d093'
  }
]
export const permissionsDataRaw = [
  {
    title: 'Dashboard',
    items: [
      {
        id: 'alllogs-read',
        title: 'All Logs',
        desc: '',
        defaultValue: true
      },
      {
        id: 'performanceall-read',
        title: 'All Performance Stats',
        desc: '',
        defaultValue: true
      },
      {
        id: 'performanceme-read',
        title: 'Performance Stats Self',
        desc: '',
        defaultValue: true
      },
      {
        id: 'selflogs-read',
        title: 'Own Logs',
        desc: '',
        defaultValue: true
      },
      {
        id: 'pendingamc-read',
        title: 'Dashbord AMC',
        desc: '',
        defaultValue: true
      },
      {
        id: 'dashbordannouncement-read',
        title: 'Dashbord Announcement',
        desc: '',
        defaultValue: true
      },
      {
        id: 'header-read',
        title: 'Sidebar Headers',
        desc: 'This is mandatory*',
        defaultValue: true
      },
      {
        id: 'dashboard-read',
        title: 'Dashboard',
        desc: 'This is mandatory*',
        defaultValue: true
      },
      {
        id: 'dashboardstats-read',
        title: 'Dashboard Stats',
        desc: '',
        defaultValue: true
      },
      {
        id: 'db-add',
        title: 'Database Download',
        desc: '',
        defaultValue: true
      },
      {
        id: 'stu-add',
        title: 'Student Attendance',
        desc: '',
        defaultValue: true
      },
      {
        id: 'tech-add',
        title: 'Teacher Attendance',
        desc: '',
        defaultValue: true
      }
    ]
  },
  {
    title: 'Announcement',
    items: [
      {
        id: 'announcement-add',
        title: 'Announcement Add',
        desc: '',
        defaultValue: true
      },
      {
        id: 'announcement-read',
        title: 'Announcement Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'announcement-update',
        title: 'Announcement Update',
        desc: '',
        defaultValue: true
      },
      {
        id: 'announcement-detail',
        title: 'Announcement Detail',
        desc: '',
        defaultValue: true
      },
      {
        id: 'announcement-delete',
        title: 'Announcement Delete',
        desc: '',
        defaultValue: true
      }
    ]
  },
  {
    title: 'Users',
    items: [
      {
        id: 'users-add',
        title: 'Users Add',
        desc: '',
        defaultValue: true
      },
      {
        id: 'users-read',
        title: 'Users Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'users-update',
        title: 'Users Update',
        desc: '',
        defaultValue: true
      },
      {
        id: 'users-detail',
        title: 'Users Detail',
        desc: '',
        defaultValue: true
      },
      {
        id: 'users-delete',
        title: 'Users Delete',
        desc: '',
        defaultValue: true
      }
    ]
  },
  {
    title: 'Roles & Permissions',
    items: [
      {
        id: 'roles-read',
        title: 'Roles & Permissions Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'roles-update',
        title: 'Roles & Permissions Update',
        desc: '',
        defaultValue: true
      }
    ]
  },
  // new role
  {
    title: 'Students',
    items: [
      {
        id: 'student-add',
        title: 'Student Add',
        desc: '',
        defaultValue: true
      },
      {
        id: 'student-read',
        title: 'Student Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'studentapproval-update',
        title: 'Student Pending List',
        desc: '',
        defaultValue: true
      },
      {
        id: 'student-update',
        title: 'Student Update',
        desc: '',
        defaultValue: true
      },
      {
        id: 'student-detail',
        title: 'Student Detail',
        desc: '',
        defaultValue: true
      },
      {
        id: 'student-delete',
        title: 'Student Delete',
        desc: '',
        defaultValue: true
      }
    ]
  },

  {
    title: 'Location',
    items: [
      {
        id: 'location-add',
        title: 'Location Add',
        desc: '',
        defaultValue: true
      },
      {
        id: 'location-read',
        title: 'Location Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'location-update',
        title: 'Location Update',
        desc: '',
        defaultValue: true
      },
      {
        id: 'location-detail',
        title: 'Location Detail',
        desc: '',
        defaultValue: true
      },
      {
        id: 'location-delete',
        title: 'Location Delete',
        desc: '',
        defaultValue: true
      }
    ]
  },

  {
    title: 'Classes',
    items: [
      {
        id: 'class-add',
        title: 'Class Add',
        desc: '',
        defaultValue: true
      },
      {
        id: 'class-read',
        title: 'Class Read',
        desc: '',
        defaultValue: true
      },
      {
        id: 'class-update',
        title: 'Class Update',
        desc: '',
        defaultValue: true
      },
      {
        id: 'class-detail',
        title: 'Class Detail',
        desc: '',
        defaultValue: true
      },
      {
        id: 'class-delete',
        title: 'Class Delete',
        desc: '',
        defaultValue: true
      }
    ]
  }
]
